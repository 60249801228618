import React, { useState, useRef, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Config,
  getDecryptedData,
  getEncryptedData,
  schemaMarkup,
} from "../../config";
import axios from "axios";
import Loader from "../components/Common/Loader";
import SvgDivider from "../components/Common/SvgDivider";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

const TherapistJob = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const targetSectionRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  const [showSection, setShowSection] = useState(false);

  const customToastOptions = {
    style: {
      border: "1px transparent #ccc",
      padding: "30px 50px",
      backgroundColor: "#c19356",
      color: "white",
      borderRadius: "10px",
      fontSize: "1.3rem",
      whiteSpace: "nowrap",
      minWidth: "400px",
      fontWeight: "300",
    },
  };

  const handleButtonClick = () => {
    // if (targetSectionRef.current) {
    //     targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    setShowSection(!showSection);
    scrollToSection();
  };

  const scrollToSection = () => {
    const section = document.getElementById("application-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [emergencyContact, setEmergencyContact] = useState('');
  const [experience, setExperience] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    // emergencyContact: '',
    experience: "",
  });

  const handleValidation = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required";
    }

    // if (!emergencyContact) {
    //     newErrors.emergencyContact = "Emergency Contact is equired";
    // }
    if (!experience) {
      newErrors.experience = "Years of Experience is required";
    }

    if (!phone) {
      newErrors.phone = "Phone Number is required";
    } else if (!/^[+\d]{7,15}$/.test(phone)) {
      newErrors.phone = "Invalid Phone Number";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearFields = () => {
    setName("");
    setPhone("");
    setEmail("");
    // setEmergencyContact("");

    setExperience("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const data = new FormData();

      data.append("name", getEncryptedData(name));
      data.append("email", email);
      data.append("phone", getEncryptedData(phone));
      data.append("contact", "");
      data.append("membership_number", "");
      data.append("ico_number", "");
      data.append("modality", "");
      data.append("type", 2);
      data.append("experience", getEncryptedData(experience));
      data.append("description", "");
      data.append("image", "");
      data.append("string_data", getDecryptedData(name));

      setLoading(true);

      axios
        .post(`${Config.apiUrl}/register`, data, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.success === true) {
            toast.success(
              <div>
                Registered Successfully!
                <br />
                Please check your mail.
              </div>
            );
            clearFields();
          } else if (data.data === "email already taken") {
            toast.error("This email is already taken!", {
              iconTheme: {
                primary: "#c19356",
                secondary: "#c19356",
              },
            });
          } else {
            toast.error("Something Went Wrong!");
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
          console.error("There was a problem fetching the data:", error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/become-a-therapist' />
      </Helmet>
      <div className="overflow">
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={customToastOptions}
        />
        {isLoading && <Loader />}
        {/* <div className="getstart-main"> */}
        <div className="page-container">
          <Navbar />
          {/* <div data-aos="fade-in" ref={targetSectionRef}>
                    <TherapistSlider />
                </div> */}

          <div>
            <div className="join-us" data-aos="fade-in">
              <h1>Become a Therapist</h1>
            </div>
          </div>

          <div className="join-content">
            <div className="bg-white">
              <div>
                <div className="why-join">
                  <div className="join-text" data-aos="fade-in">
                    <h1>Why Join Jean Therapy team?</h1>
                  </div>
                  <div className="join-box">
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-clock"></i>
                      <span className="bold-text">Flexibility</span>
                      <span className="main-text">
                        There’s no hassle with going to an office. Instead you
                        can work with all of your clients from the comfort of
                        your home, during hours that suits you.
                      </span>
                    </div>
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-currency-pound"></i>
                      <span className="bold-text">Additional income</span>
                      <span className="main-text">
                        Your work at Jean Therapy can be a major source of
                        income or just a supplement to your current day-to-day
                        therapy job.
                      </span>
                    </div>
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-star"></i>
                      <span className="bold-text">Top Quality Therapy</span>
                      <span className="main-text">
                        You’ll be using a therapy platform that is based on
                        well-researched therapy treatments. Helping your clients
                        via worksheets, messages and live sessions (video, voice
                        or text chat).
                      </span>
                    </div>
                  </div>
                  <div className="join-box">
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-check-circle"></i>
                      <span className="bold-text">No hassle</span>
                      <span className="main-text">
                        We’ll take care of acquiring clients, billing,
                        administrations etc so that you can focus on your
                        clients 100%.
                      </span>
                    </div>
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-app-indicator"></i>
                      <span className="bold-text">
                        Easy notification system
                      </span>
                      <span className="main-text">
                        From your dashboard and email notifications you can
                        easily see what clients are expecting your feedback and
                        upcoming chats with clients.
                      </span>
                    </div>
                    <div className="box1" data-aos="fade-in">
                      <i className="bi bi-file-lock"></i>
                      <span className="bold-text">Secure & confidential</span>
                      <span className="main-text">
                        All the communication is anonymously stored on our
                        secure server, where only you and your client can access
                        it.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <SvgDivider />

              <div className="bg-blue">
                <div className="how-it-works">
                  <div className="works-title" data-aos="fade-out">
                    <h1>How it Works</h1>
                  </div>
                  <div className="works-content">
                    <div className="work-box" data-aos="fade-out">
                      <div className="number">01</div>
                      <div className="work-heading">
                        Submit Your Application Form
                      </div>
                      <div className="work-text">
                        We’d love to hear about your quirks, past experience,
                        work/communication style, and anything else that you
                        want us to know about!{" "}
                      </div>
                    </div>
                    <div className="work-box" data-aos="fade-out">
                      <div className="number">02</div>
                      <div className="work-heading">
                        We Will Get in Touch With You{" "}
                      </div>
                      <div className="work-text">
                        Applying for a job is a big decision, one that we don’t
                        take for granted. We’ll get back to you regarding your
                        application as soon as we can.{" "}
                      </div>
                    </div>
                    <div className="work-box" data-aos="fade-out">
                      <div className="number">03</div>
                      <div className="work-heading">
                        Show Us How Exceptional You Are!{" "}
                      </div>
                      <div className="work-text">
                        Ensure your profile is complete with all related
                        information so customers can choose you as their
                        therapist based on your details
                      </div>
                    </div>
                    {/* <div className='work-box' data-aos="fade-out">
                                        <div className='number'>04</div>
                                        <div className='work-heading'>Let’s Talk Potential! </div>
                                        <div className='work-text'>If we like your work, wit, and skills,
                                            we can discuss how you could harness your skills for Heart It Out!
                                            And of course, you’d be a part of the family. </div>
                                    </div> */}
                  </div>
                </div>
              </div>

              <div className="">
                <div style={{ height: "150px", overflow: "hidden" }}>
                  <svg
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <path
                      d="M-1.41,84.38 C236.74,-40.95 293.74,149.50 500.85,46.88 L500.28,-0.50 L0.00,0.00 Z"
                      style={{ stroke: "none", fill: "#e4f4f3" }}
                    ></path>
                  </svg>
                </div>
              </div>

              <div className="requirements bg-white">
                <div className="title" data-aos="fade-in">
                  <h1>Requirements</h1>
                </div>
                <div className="req-content">
                  <div className="req-content1" data-aos="fade-in">
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      Proof of qualification/s
                    </div>
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      Individual registration/accreditation number
                    </div>
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      2+ years of experience in therapy for adults, couples,
                      and/or teens
                    </div>
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      Desktop or laptop computer with a reliable internet
                      connection and a webcam
                    </div>
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      ICO number
                    </div>
                    <div className="req-box">
                      <i className="bi bi-check-circle-fill"></i>
                      Proof of insurance with expiry date{" "}
                    </div>
                  </div>
                </div>
                <div className="application-btn" data-aos="fade-in">
                  <button onClick={handleButtonClick}>Start Application</button>
                </div>
              </div>

              {/* {!showSection &&
                            <div className="">
                                <div style={{ height: '150px', overflow: 'hidden' }} >
                                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                                        <path d="M-0.84,109.03 C202.88,-0.48 319.69,211.67 500.84,69.57 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#e4f4f3' }}>
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        } */}

              <div id="application-section">
                {showSection && (
                  <div className="application-form">
                    <div>
                      <div className="filldet-title" data-aos="fade-in">
                        <h1>Please Fill Details</h1>
                      </div>
                      <div className="filldet-form" data-aos="fade-in">
                        <div className="row">
                          <div className="col-sm-6">
                            <p>
                              <label>
                                Name <i className="text-danger">*</i>
                              </label>
                              <br />
                              <span>
                                <input
                                  className="form-control"
                                  value={name}
                                  type="text"
                                  placeholder="Enter Name"
                                  onKeyPress={(e) => {
                                    if (/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                ></input>
                                {errors.name && (
                                  <span className="text-danger">
                                    {errors.name}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <p>
                              <label>
                                Email <i className="text-danger">*</i>
                              </label>
                              <br />
                              <span>
                                <input
                                  className="form-control"
                                  value={email}
                                  type="text"
                                  placeholder="Enter Email"
                                  onChange={(e) => {
                                    setEmail(e.target.value.toLowerCase());
                                  }}
                                ></input>
                                {errors.email && (
                                  <span className="text-danger">
                                    {errors.email}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <p>
                              <label>
                                Phone Number <i className="text-danger">*</i>
                              </label>
                              <br />
                              <span>
                                <input
                                  className="form-control"
                                  value={phone}
                                  type="text"
                                  placeholder="Enter Phone Number"
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    const sanitizedValue =
                                      e.target.value.replace(/[^0-9]/g, "");
                                    setPhone(sanitizedValue);
                                  }}
                                ></input>
                                {errors.phone && (
                                  <span className="text-danger">
                                    {errors.phone}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                          {/* <div className='col-sm-6'>
                                                    <p>
                                                        <label>Emergency Contact <i className='text-danger'>*</i></label><br />
                                                        <span>
                                                            <input className='form-control' value={emergencyContact}
                                                                type='text' placeholder='Enter Emergency Contact'
                                                                onKeyPress={(e) => {
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setEmergencyContact(e.target.value)
                                                                }}
                                                            ></input>
                                                            {
                                                                errors.emergencyContact && <span className='text-danger'>{errors.emergencyContact}</span>
                                                            }
                                                        </span>
                                                    </p>
                                                </div> */}

                          <div className="col-sm-6">
                            <p>
                              <label>
                                Years of experience{" "}
                                <i className="text-danger">*</i>
                              </label>
                              <br />
                              <span>
                                <input
                                  className="form-control"
                                  value={experience}
                                  type="text"
                                  placeholder="Enter Years of Experience"
                                  onChange={(e) => {
                                    setExperience(e.target.value);
                                  }}
                                ></input>
                                {errors.experience && (
                                  <span className="text-danger">
                                    {errors.experience}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                          {/* <div className='padding-seperator'></div> */}
                          <div className="col-sm-12 button-div">
                            <p>
                              <input
                                className="form-control"
                                onClick={handleSubmit}
                                type="submit"
                                value="Submit Application"
                              ></input>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className='review-therapist bg-blue'>
                            <div className='therapist-title' data-aos="fade-in">
                                <h1>Therapists Review</h1>
                            </div>
                            <div className='therapist-review'>
                                <div className='reviewbox' data-aos="fade-right">
                                    <div><i className="bi bi-quote"></i></div>
                                    <div>
                                        <div className='reviewbox1'>
                                            Jean Therapy provides a simple, efficient platform that empowers counsellors to provide services autonomously, based on their own professional judgement. That autonomy is backed up by responsive support from Jean Therapy staff, and a robust community of counsellors offering peer support and consultation.
                                        </div>
                                        <div className='counselor-text'>
                                            <img src={img1}></img>
                                            <div>
                                                <span>Rachel Williams</span>
                                                <span>Counsellor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='reviewbox' data-aos="fade-up">
                                    <div><i className="bi bi-quote"></i></div>
                                    <div>
                                        <div className='reviewbox1'>
                                            Jean Therapy provides a simple, efficient platform that empowers counsellors to provide services autonomously, based on their own professional judgement. That autonomy is backed up by responsive support from Jean Therapy staff, and a robust community of counsellors offering peer support and consultation.
                                        </div>
                                        <div className='counselor-text'>
                                            <img src={img2}></img>
                                            <div>
                                                <span>Alona Tucker</span>
                                                <span>Counsellor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='reviewbox' data-aos="fade-left">
                                    <div><i className="bi bi-quote"></i></div>
                                    <div>
                                        <div className='reviewbox1'>
                                            Jean Therapy provides a simple, efficient platform that empowers counsellors to provide services autonomously, based on their own professional judgement. That autonomy is backed up by responsive support from Jean Therapy staff, and a robust community of counsellors offering peer support and consultation.
                                        </div>
                                        <div className='counselor-text'>
                                            <img src={img3}></img>
                                            <div>
                                                <span>Catherine Hubery</span>
                                                <span>Counsellor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default TherapistJob;
