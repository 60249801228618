import React, { useState, useEffect } from "react";
import Logo from "../../../assets/img/logo-back.png";
import { Config } from "../../../config";
import { NavLink } from "react-router-dom";

const Navbar = ({ pageName }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const [showLoginButton, setShowLoginButton] = useState(true);

  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const toggleLoginDropdown = () => {
    setShowLoginDropdown(!showLoginDropdown);
  };

  let isFirstPage = false;
  if (pageName === "homepage2") {
    isFirstPage = true;
  } else {
    isFirstPage = false;
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setShowLoginButton(false);
      } else {
        setShowLoginButton(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`container-box pt-2 pb-3 position-relative z-999 ${
        isFirstPage ? "first-page" : ""
      }`}
    >
      <div className="row justify-content-center navbar-row">
        <div className="col-5 d-none d-lg-block">
          <nav className="navbar navbar-expand-lg justify-content-end z-999">
            <ul className="navbar-nav left-navigation custom-menu">
              <li className="">
                <NavLink className="" to={`${Config.appUrl}`}>
                  Home
                </NavLink>
              </li>
              <li className="">
                <NavLink className="" to={`${Config.appUrl}get-counselling`}>
                  Get Counselling
                </NavLink>
              </li>
              <li className="">
                <NavLink className="" to={`${Config.appUrl}become-a-therapist`}>
                  Become a Therapist
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-2 d-none d-lg-block">
          <div className="text-center">
            <NavLink to={`${Config.appUrl}`}>
              <img
                src={Logo}
                alt="Jeantherapyonline Logo"
                className=""
                width="170"
              />
            </NavLink>
          </div>
        </div>
        <div className="col-12 d-lg-none text-end">
          <div className="mobilemenu-div">
            <div className="">
              <NavLink to={`${Config.appUrl}`}>
                <img
                  src={Logo}
                  alt="Jeantherapyonline Logo"
                  className=""
                  width="140"
                />
              </NavLink>
            </div>
            <div>
              <i
                className="bi bi-list fs-1 menu-btn text-white py-0 px-2 bg-dark1 rounded-circle"
                onClick={() => setMobileMenu(!mobileMenu)}
              ></i>
            </div>
          </div>
        </div>
        <div className="col-5 d-none d-lg-block">
          <nav className="navbar navbar-expand-lg justify-content-start z-999">
            <ul className="navbar-nav left-navigation ">
              <li className="">
                <NavLink className="" to={`${Config.appUrl}blog`}>
                  Blog
                </NavLink>
              </li>
              <li className="">
                <NavLink className="" to={`${Config.appUrl}aboutus`}>
                  About Us
                </NavLink>
              </li>
              <li className="">
                <NavLink className="" to={`${Config.appUrl}contactus`}>
                  Contact Us
                </NavLink>
              </li>
              <li className="pt-0">
                <div
                  className="dropdown"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="text-end1">
                    <NavLink
                      className={`button ${isFirstPage ? "white-text" : ""}`}
                      to="#"
                    >
                      Login &#9662;
                    </NavLink>
                  </div>
                  {isOpen && (
                    <div className="dropdown1">
                      <div>
                        <NavLink
                          to="https://www.jeantherapyonline.co.uk/customer/login"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Customer Login
                        </NavLink>
                      </div>
                      <div>
                        <NavLink
                          to="https://www.jeantherapyonline.co.uk/therapist/login"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Therapist Login
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className={`mobile-menu ${mobileMenu && "showMenu"}`}>
        <div className="bg-overlay"></div>
        <ul className="mobile-navlinks ps-0 pt-2">
          <li className="d-flex justify-content-end mb-3">
            <h2
              onClick={() => setMobileMenu(false)}
              className="menu-btn text-center px-3 py-2 me-4 mt-4 rounded-circle text-white"
              style={{ backgroundColor: "#71d5ef" }}
            >
              &times;
            </h2>
          </li>
          <li>
            <NavLink to={`${Config.appUrl}`}>Home</NavLink>
          </li>

          <li>
            <NavLink to={`${Config.appUrl}get-counselling`}>
              Get Counselling
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Config.appUrl}become-a-therapist`}>
              Become a Therapist
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Config.appUrl}blog`}>Blog</NavLink>
          </li>
          <li>
            <NavLink to={`${Config.appUrl}aboutus`}>About Us</NavLink>
          </li>
          <li>
            <NavLink to={`${Config.appUrl}contactus`}>Contact Us</NavLink>
          </li>

          <li>
            <NavLink onClick={toggleLoginDropdown}>Login&nbsp; &#9662;</NavLink>
          </li>
          {showLoginDropdown && (
            <li className="text-center">
              <div className="col-12 d-lg-none text-center">
                <div className="dropdown-menu1">
                  <NavLink
                    className="dropdown-item text-center"
                    to="https://www.jeantherapyonline.co.uk/customer/login"
                    target="_blank"
                  >
                    Customer Login
                  </NavLink>
                  <NavLink
                    className="dropdown-item text-center"
                    to="https://www.jeantherapyonline.co.uk/therapist/login"
                    target="_blank"
                  >
                    Therapist Login
                  </NavLink>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
