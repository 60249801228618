import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Config, schemaMarkup } from "../../../config";
import PaymentSuccessImg from "../../../assets/img/sucess-payment.avif";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Common/Navbar";
import axios from "axios";
import Loader from "../../components/Common/Loader";

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionIdFromUrl = queryParams.get("session_id");

    const onGetStatus = async () => {
      setLoading(true);
      try {
        await axios({
          method: "get",
          url: `${Config.apiUrl}/getSessionData/${sessionIdFromUrl}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          console.log(res);
          if (res?.data == true) {
            setLoading(false);
          } else {
            setLoading(false);
            navigate(`${Config.appUrl}failed`);
          }
        });
      } catch (error) {
        console.log(error);
        navigate(`${Config.appUrl}failed`);
        setLoading(false);
      }
    };
    onGetStatus();
  }, []);

  return (
    <div className="notfound bg-white">
      {loading && <Loader />}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk" />
      </Helmet>
      <div>
        <div className="notfound-img ">
          <Navbar />
        </div>
        {loading === false ? (
          <div className="notfound-content pt-0">
            <div className="notfound-flex align-items-center">
              <div className="content-div col-md-6">
                <h2 className="payment-success">Payment successful !</h2>
                <p>
                  Thank you for your purchase. Your transaction has been
                  completed, and we’re excited to have you on board.
                </p>
                <div className="home-button">
                  <NavLink to={`${Config.appUrl}`}>Go to Homepage</NavLink>
                </div>
              </div>
              <div className="notfound_img ">
                <img
                  loading="lazy"
                  src={PaymentSuccessImg}
                  alt="Jeantherapyonline Logo"
                ></img>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5 mb-5 text-center">
            <div class="spinner-border text-primary" role="status"></div>
            <h3>Wait a moment</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
