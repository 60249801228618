import React from 'react';
import Modal from 'react-modal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckOutForm';

const stripePromise = loadStripe('pk_live_51PVbOpGnRAVN4Ix13KVoGvYZanWrevfuELUvgtyrbOsvqnfd5LNLrAnc7SWrOYxhhOuzuhBgCRg1soBPvoOSaJLV00zQUOHcRk ');

// pk_test_51PVbOpGnRAVN4Ix1JEUlxPoQLa4IrrndBB5AwurqxOcc7yhclGtTNESzQ5vEHsw2G2t6ladKCsDlZEqPY50kynfl00Zml12ryT  (jean test key)
// pk_live_51PVbOpGnRAVN4Ix13KVoGvYZanWrevfuELUvgtyrbOsvqnfd5LNLrAnc7SWrOYxhhOuzuhBgCRg1soBPvoOSaJLV00zQUOHcRk  (jean Live key)

const PaymentModal = ({ isOpen, onClose, total , data }) => {
  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '800px',
          borderRadius: '8px',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
          border: 'none',
          padding: '50px 40px',
          width: '550px',
          backgroundColor: '#e4f4f3'
        }
      }}
    >
      <div className='payment-div'>
        <h2 className='mb-0'>Complete your payment</h2>
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      {/* <h2 className='pay-h2'>Complete your payment</h2> */}
      <Elements stripe={stripePromise}>
        <CheckoutForm MainTotal={total} data={data} onClosePaymentModal={onClose} />
      </Elements>
    </Modal>
  );
};

export default PaymentModal;
