import React, { useState } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const MAX = 10;
const MIN = 0;
const marks = [
  {
    value: MIN,
    label: '',
  },
  {
    value: MAX,
    label: '',
  },
];

const RangeSlider = ({onSliderChange}) => {

    const [val, setVal] = React.useState(MIN);
    const handleChange = (_, newValue) => {
        setVal(newValue);
        onSliderChange(newValue);
    };

    return (
        <span className="range-slider">
            <Box sx={{ width: 250 }}>
                <Slider
                    marks={marks}
                    step={1}
                    value={val}
                    valueLabelDisplay="auto"
                    min={MIN}
                    max={MAX}
                    onChange={handleChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                        variant="body2"
                        onClick={() => setVal(MIN)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {MIN}
                    </Typography>
                    <Typography
                        variant="body2"
                        onClick={() => setVal(MAX)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {MAX}
                    </Typography>
                </Box>
            </Box>
            {/* <input className='' value={healthassesment.impact}
            name="impact"
            type='range'
            min="0"
            max="10"
            onChange={handleChange}
        ></input>  */}
            {/* {
            errors.healthproblem && <span className='text-danger'>{errors.healthproblem}</span>
        } */}
        </span>
    );
}
export default RangeSlider