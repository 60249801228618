import CryptoJS from "crypto-js";

export const Config = {
  appUrl: "/",
  apiUrl: 'https://jeantherapyonline.co.uk/backend/public/api'
  // apiUrl: "https://ztpl.net/jeantheraphy/backend/public/api",
};

export const SECRET_KEY = "JYEPAANRTEHHETRNAAPEYJ"

export const getEncryptedData = (normalText) => {
  if (normalText) {
    try {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(normalText),
        SECRET_KEY
      ).toString();
      return (data);
    } catch (error) {
      // console.log("enc Invalid Key")
    }
  }
};

export const getDecryptedData = (encryptedText) => {
  if (encryptedText) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return (data);

    } catch (error) {
      // console.log("dec Invalid Key")
      return encryptedText
    }
  } else {
    return encryptedText
  }
};

export const schemaMarkup = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Jean Therapy Online",
  alternateName: "Jean Therapy Online",
  url: "https://www.jeantherapyonline.co.uk/",
  logo: "https://www.jeantherapyonline.co.uk/static/media/logo.f7be3b7c1dda1c9e58a2.jpg",
  sameAs: [
    "https://www.facebook.com/ipsumcounsellingandcoaching/",
    "https://www.instagram.com/jeantherapyonline/?hl=en",
    "https://www.linkedin.com/in/jean-kimberley-langford-ncps-accred-diphe-dso-mhfa-690853131/",
  ],
};